<template>
  <div>
    <a @click="$router.push({ name: 'partners-list' })">
      <feather-icon
        icon="ArrowLeftIcon"
        class="cursor-pointer align-bottom"
      />Back to list
    </a>
    <div v-if="partner">
      <vx-card class="mt-5">
        <div slot="header">
        <h3 class="font-bold">Merchant details</h3>
        </div>
        <vs-row class="mt-5">
          <vs-col vs-w="6" vs-xs="12">
            <div class="flex flex-wrap items-center content-between wrap-span">
              <label>Merchant name</label>
              <span class="font-bold">{{ partner.companyName }}</span>
            </div>
            <div class="flex flex-wrap items-center content-between wrap-span">
              <label>Phone</label>
              <span class="font-bold">{{ partner.phoneNumber }}</span>
            </div>
            <div class="flex flex-wrap items-center content-between wrap-span">
              <label>Email</label>
              <span class="font-bold">
                <a :href="'mailto:' + partner.email">{{ partner.email }}</a>
              </span>
            </div>
            <div class="flex flex-wrap items-center content-between wrap-span">
              <label>Sms name</label>
              <span class="font-bold">
                {{ partner.smsName }}
              </span>
            </div>
            <div class="flex flex-wrap items-center content-between wrap-span">
              <label>Payment Expiry</label>
              <span class="font-bold">
                {{ partner.paymentExpiry }} days
              </span>
            </div>
            <div class="flex flex-wrap items-center content-between wrap-span">
              <label>Request types</label>
              <ul>
                <li v-for="(type, key) in partner.requestTypes" :key="key">
                  <span class="font-bold">
                    {{ type | capitalize }}
                  </span>
                </li>
              </ul>
            </div>
            <div
              class="flex flex-wrap items-center content-between wrap-span"
              v-if="partner.createdAt"
            >
              <label>Created date</label>
              <span class="font-bold">
                {{ moment(partner.createdAt) }}
              </span>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>
      <vx-card class="mt-5">
        <h3 class="font-bold">Admin</h3>
        <vs-row class="mt-5">
          <vs-col vs-w="4" vs-xs="12">
            <div class="flex flex-wrap items-center content-between wrap-span">
              <label>First name</label>
              <span class="font-bold">{{ partner.firstName }}</span>
            </div>
            <div class="flex flex-wrap items-center content-between wrap-span">
              <label>Last name</label>
              <span class="font-bold">{{ partner.lastName }}</span>
            </div>
            <div class="flex flex-wrap items-center content-between wrap-span">
              <label>Email</label>
              <span class="font-bold">
                <a :href="'mailto:' + partner.email">{{ partner.email }}</a>
              </span>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>
      <vx-card class="mt-5 custom-vx-card">
        <div class="flex">
          <h3 class="font-bold ml-5">Connectors</h3>
          <vs-button v-round class="ml-auto mr-5" @click="handleAddConnector"
            >Add connector</vs-button
          >
        </div>

        <vs-table
          :data="connectors"
          max-items="5"
          :pagination="connectors.length ? true : false"
          class="mt-5"
        >
          <template slot="thead">
            <vs-th>
              Api key
            </vs-th>
            <vs-th>
              Public integration
            </vs-th>
            <vs-th>
              Action
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.apiKey">
                {{ tr.apiKey }}
              </vs-td>
              <vs-td :data="tr.publicIntegration">
                {{ tr.publicIntegration }}
              </vs-td>

              <vs-td :data="tr">
                <feather-icon
                icon="EditIcon"
                svgClasses="h-4 w-4"
                class="ml-1 cursor-pointer"
                @click="handleEdit(tr)"
              />
                <feather-icon
                icon="Trash2Icon"
                svgClasses="h-4 w-4"
                class="ml-1 pl-2 cursor-pointer"
                @click="confirmDeleteAlert(tr._id)"
              />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
      <vx-card class="mt-5 custom-vx-card">
        <h3 class="font-bold ml-5">Staffs</h3>

        <vs-table :data="partner.staffs" max-items="5" pagination class="mt-5">
          <template slot="thead">
            <vs-th>
              First name
            </vs-th>
            <vs-th>
              Last name
            </vs-th>
            <vs-th>
              Phone
            </vs-th>
            <vs-th>
              Email
            </vs-th>
            <vs-th>
              Status
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.firstName">
                {{ tr.firstName }}
              </vs-td>
              <vs-td :data="tr.lastName">
                {{ tr.lastName }}
              </vs-td>
              <vs-td :data="tr.phoneNumber">
                {{ tr.phoneNumber }}
              </vs-td>
              <vs-td :data="tr.email">
                {{ tr.email }}
              </vs-td>
              <vs-td :data="tr.accountStatus">
                {{ tr.accountStatus }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </div>

    <!-- Connectors -->
    <vs-popup
      class="holamundo"
      title="Add new connector"
      :active.sync="connectorPopUp"
    >
      <vx-card>
        <vs-row>
          <vs-col vs-w="12">
            <div>
              <vs-input
                class="w-full"
                v-validate="'required'"
                size="large"
                label-placeholder="Api key*"
                v-model="apiKey"
                name="apiKey"
              />
              <span class="text-danger text-sm" v-show="errors.has('apiKey')">
                {{ errors.first("apiKey") }}
              </span>
            </div>
            <div class="my-5">
              <vs-input
                class="w-full"
                v-validate="'required'"
                size="large"
                label-placeholder="Public integration*"
                v-model="publicIntegration"
                name="publicIntegration"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('publicIntegration')"
              >
                {{ errors.first("publicIntegration") }}
              </span>
            </div>
          </vs-col>
          <vs-col class="flex items-center justify-end" vs-type="flex">
            <vs-button v-round flat class="primary" @click="prepareSubmitConnector">
              Save
            </vs-button>
            <vs-button v-round flat
              @click="cancel"
              class="ml-8 font-bold underline link-danger primary danger"
            >
              Cancel
            </vs-button>
          </vs-col>
        </vs-row>
      </vx-card>
    </vs-popup>
  </div>
</template>
<script lang="ts">
import { mapActions } from "vuex";
import moment from "moment";
import { Validator } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";

import "sweetalert2/src/sweetalert2.scss";
import { DEFAULT_PAYMENT_EXPIRY } from './constant';
const dictionary = {
  en: {
    attributes: {
      publicIntegration: "public integration",
      apiKey: "api key"
    }
  }
};
Validator.localize(dictionary);
export default {
  data() {
    return {
      connectorPopUp: false,
      partner: "",
      connectors: [],
      serverUrl: process.env.VUE_APP_API_URL,
      connectorId: "",
      apiKey: "",
      publicIntegration: ""
    };
  },
  methods: {
    ...mapActions("partner", ["fetchPartnerDetailById"]),
    ...mapActions("connector", [
      "fetchConnectorByPartnerId",
      "storeConnector",
      "updateConnectorById",
      "deleteConnectorById"
    ]),
    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    async getPartnerDetail() {
      await this.fetchPartnerDetailById(this.$route.params.id)
        .then(result => {
          this.partner = result.data.data;
          if(!this.partner.paymentExpiry){
            this.partner.paymentExpiry = DEFAULT_PAYMENT_EXPIRY
          }
        })
        .catch(err => {
          
        });
    },
    async getConnectorList() {
      await this.fetchConnectorByPartnerId(this.$route.params.id)
        .then(result => {
          this.connectors = result.data.data;
        })
        .catch(err => {
          
        });
    },
    handleAddConnector() {
      this.connectorPopUp = true;
    },
    prepareSubmitConnector() {
      this.$vs.loading();
      this.$validator.validateAll().then(async result => {
        if (result) {
          const data = {
            partnerId: this.$route.params.id,
            apiKey: this.apiKey,
            publicIntegration: this.publicIntegration
          };
          if (!this.connectorId) this.saveConnector(data);
          else this.updateConnector(data);
        }
        this.$vs.loading.close();
      });
    },
    cancel() {
      this.init();
      this.connectorPopUp = false;
    },
    async saveConnector(data) {
      await this.storeConnector(data)
        .then(result => {
          this.$vs.loading.close();
          this.showMessage(
            "Success",
            "Connector has been created successfully.",
            "success",
            "icon-check-circle"
          );
          this.connectorPopUp = false;

          this.getConnectorList();
        })
        .catch(err => {
          this.$vs.loading.close();

          this.showMessage("Error", err.message, "danger", "icon-times");
        });
    },
    async updateConnector(data) {
      let obj = {
        id: this.connectorId,
        data: data
      };
      await this.updateConnectorById(obj)
        .then(result => {
          this.$vs.loading.close();
          this.showMessage(
            "Success",
            "Connector has been update successfully.",
            "success",
            "icon-check-circle"
          );
          this.connectorPopUp = false;

          this.getConnectorList();
        })
        .catch(err => {
          this.$vs.loading.close();

          this.showMessage("Error", err.message, "danger", "icon-times");
        });
    },
    init() {
      this.connectorId = "";
      this.apiKey = "";
      this.publicIntegration = "";
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,

      });
    },
    handleEdit(connector) {
      this.connectorId = connector._id;
      this.apiKey = connector.apiKey;
      this.publicIntegration = connector.publicIntegration;
      this.connectorPopUp = true;
    },
    confirmDeleteAlert(id){
      let self = this;
      Swal.fire({
        title: "Are you sure?",
        text: "The connector will be permanently deleted.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          self.handleDelete(id);
        }
      });
    },
    async handleDelete(id) {
      this.$vs.loading();
      await this.deleteConnectorById(id)
        .then(result => {
          this.$vs.loading.close();
          this.showMessage(
            "Successful",
            "User has been deleted successfully.",
            "success",
            "icon-check-circle"
          );
          this.getConnectorList();
        })
        .catch(err =>
        {
          this.$vs.loading.close();
          {}
        });
    },
  },
  watch: {
    connectorPopUp(res) {
      if (!res) {
        this.init();
      }
    }
  },
  created() {
    this.getPartnerDetail();
    this.getConnectorList();
  }
};
</script>
